import classnames from "classnames"
import { jwtDecode } from "jwt-decode"
import _ from "lodash"
import moment from "moment"

let helpers = {
	formField: (f, name, opts) => {
		return classnames("formList__item", {
			"is-invalid": f.errors[name] && f.touched[name],
			"is-valid": !f.errors[name] && f.touched[name] && f.values[name],
			full: opts?.full,
		})
	},
	currentUser: () => {
		let user = localStorage.getItem("user")
		user = jwtDecode(user)
		return user
	},
	buildOpts: (d, useFull, opts) => {
		// usefull is for making the labels the full csv value, like Section, Subdivision, Community.  Comes from the server.
		let ret = []
		if (!d?.data) return []
		_.forEach(d.data, (item) => {
			if (useFull) {
				ret.push({ value: item.id, label: item.full })
			} else {
				ret.push({ value: item.id, label: item.name })
			}
		})
		return ret
	},
	buildDbOptions: (opts, name) => {
		if (!opts?.data) return []
		// This is specifically for the options that come from the db, like amenities
		let ret = []
		let f = _.find(opts.data, { name })
		if (!f) return []
		_.forEach(f.value, (item) => {
			ret.push({ value: item.value, label: item.label })
		})
		return ret
	},
	buildPayloadOptions: (opts, name) => {
		if (!opts) return []
		let ret = []
		_.forEach(opts, (v) => {
			ret.push({ value: v.id, label: v.name })
		})
		return ret
	},
	buildNeighborhoods: (opts, name) => {
		if (!opts) return []
		let ret = []
		_.forEach(opts, (v) => {
			ret.push({ value: v.id, label: v.public_name || v.name })
		})
		return ret
	},
	buildSubdivisions: (d) => {
		let data = d.data
		if (!data) return
		let ret = []
		_.forEach(data, (v) => {
			ret.push({ value: v.id, label: `${v.name} | (${v.public_name})` })
		})
		return ret
	},
	buildSchools: (d, school_type) => {
		let ret = []
		if (!d?.data?.data) return []
		_.forEach(d.data.data, (item) => {
			if (item.school_type !== school_type) return
			ret.push({ value: item.id, label: item.name })
		})
		return ret
	},
	translateID: (name, row, d) => {
		if (!d?.data) return ""
		let ret = _.find(d.data, { id: row[name] })
		return ret?.name
	},
	colPublicName: (row, d) => {
		if (!d?.data) return ""
		let ret = _.find(d.data, { id: row.subdivision })
		return ret?.public_name
	},
	buildQuarters: (opts) => {
		// returns an array of value and label with the value being a unix timestamp and the label is quarter and year, e.g. Q1 2021.  Go back 20 years.
		let ret = []
		let start = moment("2024-04-01") // This is CBAS's epoch moment
		if (opts?.allTime) {
			start = moment("2004-04-01")
		}
		let end = moment()
		while (start.isBefore(end)) {
			ret.push({ value: start.unix(), label: `Q${start.format("Q YYYY")}` })
			start.add(3, "months")
		}
		ret = _.reverse(ret)
		return ret
	},
	selectBuildLotTyps: () => {
		// Creates lot type options in groups of 5 from 20 to 90
		let groups = []
		for (let i = 20; i <= 90; i += 5) {
			if (i % 5 === 0) {
				// ensure we're only getting multiples of 5 as starting points
				let end = i + 4
				groups.push({ value: i, label: `${i}-${end}` })
			}
		}
		//? now the 1/2 acre, 1 acre, and 1 acre+
		groups.push({ value: 1000, label: "1/2 acre" })
		groups.push({ value: 1500, label: "3/4 acre" })
		groups.push({ value: 2000, label: "1 acre" })
		groups.push({ value: 3000, label: "1 acre+" })
		return groups
	},
	buildLotTypes: () => {
		//! If you add more acreage types, make sure to add them to the translateLargeLotTypes function.
		let ret = []
		for (let i = 18; i <= 150; i++) {
			ret.push({ value: i, label: i })
		}
		//? Now 1/2 acre, 1 acre, and 1acre+
		ret.push({ value: 1000, label: "1/2 acre" })
		ret.push({ value: 1500, label: "3/4 acre" })
		ret.push({ value: 2000, label: "1 acre" })
		ret.push({ value: 3000, label: "1 acre+" })

		return ret
	},
	translateLargeLotTypes: (type) => {
		if (type === 1000) return "1/2 acre"
		if (type === 1500) return "3/4 acre"
		if (type === 2000) return "1 acre"
		if (type === 3000) return "1 acre+"
		return false
	},
	selectValue: (opts, val, allowNull) => {
		// Allow null to programatically wipe out optsion + selection...used in realtor brokerage typeahead system.
		if (val == null && !allowNull) {
			return
		}
		if (typeof val == "object" && !allowNull) {
			return opts.filter((r) => val.includes(r.value))
		} else {
			return opts.filter((r) => val === r.value)
		}
	},
	stringifyObj: (obj) => {
		let f = {}
		_.forEach(obj, (v, k) => {
			if (typeof v === "object" && v) {
				f[k] = JSON.stringify(v)
			} else {
				f[k] = v
			}
		})
		return f
	},
	observedStatuses: () => {
		return [
			{ value: 1, label: "Vacant Land" },
			{ value: 2, label: "Clearing" },
			{ value: 3, label: "WS&D" },
			{ value: 4, label: "Paving" },
		]
	},
	isFiltersEmpty: (filters) => {
		let empty = true
		_.forEach(filters, (v, k) => {
			if (v && (typeof v === "string" || typeof v === "number" || typeof v === "boolean")) {
				empty = false
				return false
			} else if (typeof v === "object" && v) {
				_.forEach(v, (v2, k2) => {
					if (v2) {
						empty = false
						return false
					}
				})
			}
			if (!empty) return false
		})
		console.log(empty)
		return empty
	},
	latestEntriesInSection(entries) {
		// Pass in a section's entries, get out an array of the latest entries for each builder/lot_type/section combo
		/* const groupedData = entries.reduce((acc, curr) => {
			const { builder, lot_type, section, quarter } = curr
			const key = `${builder}-${lot_type}-${section}`

			if (!acc[key] || curr.quarter > acc[key].quarter) {
				acc[key] = curr
			}

			return acc
		}, {})
		const result = Object.values(groupedData)
		return result */
		if (!entries.length) return []
		let newestQuarter = _.maxBy(entries, "quarter").quarter
		let latestEntries = _.filter(entries, { quarter: newestQuarter })
		return latestEntries
	},
	ttmEntriesInSection(entries) {
		// Pass in a section's entries, get out an array of the ttm entries for each builder/lot_type/section combo
		// figure out the latest quarter
		let latestEntries = this.latestEntriesInSection(entries)
		let latestQuarter = latestEntries.reduce((acc, curr) => {
			return curr.quarter > acc ? curr.quarter : acc
		}, 0)
		let yearAgo = moment.unix(latestQuarter).subtract(1, "year").startOf("quarter").unix()
		let ttmEntries = latestEntries.filter((entry) => entry.quarter >= yearAgo)

		return ttmEntries
	},
	translateArray(arr, payload) {
		_.forEach(arr, (v, k) => {
			_.forEach(v, (val, key) => {
				if (key === "amenities" && Array.isArray(val)) {
					arr[k][key] = val.map((id) => {
						let amenity = _.find(payload.amenities, { value: id })
						return amenity?.label
					})
					arr[k][key] = arr[k][key].join(", ")
				} else if (key === "county") {
					arr[k][key] = _.find(payload.counties, { id: val })?.name
				} else if (key === "developer") {
					arr[k][key] = _.find(payload.developers, { id: val })?.name
				} else if (key === "districts" && Array.isArray(val)) {
					arr[k][key] = val.map((id) => {
						let district = _.find(payload.school_districts, { id })
						return district?.name
					})
					arr[k][key] = arr[k][key].join(", ")
				} else if (key === "schools" && Array.isArray(val)) {
					arr[k][key] = val.map((id) => {
						let school = _.find(payload.schools, { id })
						return school?.name
					})
					arr[k][key] = arr[k][key].join(", ")
				}
			})
		})
		return arr
	},
	translateNamesToIds(arr, data, type) {
		let ret = []
		_.forEach(arr, (v) => {
			_.forEach(data[type], (d) => {
				if (d.name === v) {
					ret.push(d.id)
				} else if (parseInt(d.name) === parseInt(v)) {
					ret.push(d.id)
				}
			})
		})
		return ret
	},
	customSort(arr, field) {
		// This was created for sorting sections originally which would put the section sorts in order of 1, 10, 11, 12, 2, 3, 4, 5, 6, 7, 8, 9.  This fixes that and puts the numbers in order, then puts the strings at the end in alpha order.
		return _.sortBy(arr, (item) => {
			const value = item[field]

			// Split the string into parts - words and numbers
			const parts = value.split(/(\d+)/).map((part) => {
				// If the part is a number, pad it with zeros for proper sorting
				const num = parseInt(part)
				return isNaN(num) ? part : part.padStart(5, "0")
			})

			// Join back together for sorting
			return parts.join("")
		})
	},
}

export default helpers
