import numeral from "numeral"
import helpers from "helpers"

export default {
	subdivisionsColumns: [
		{
			id: "checkbox",
			header: "",
			filterable: false,
			width: 15,
		},
		{
			id: "id",
			header: "ID",
		},
		{
			id: "name",
			header: "Neighborhood",
			//cell: ({ row }) => row.original.name.element,
			//filterFn: "reactElementCheck",
		},
		{
			id: "communityName",
			header: "Community",
		},
		{
			id: "developerName",
			header: "Developer",
		},
		{
			id: "status",
			header: "Status",
		},
		{
			id: "prices",
			header: "Price Range",
			cell: (v) => {
				let val = v.getValue()
				if (!val?.min && !val?.max) return ""
				return `${numeral(v.getValue().min).format("$0ak")} - ${numeral(v.getValue().max).format("$0ak")}`
			},
		},
		{
			id: "lot_types",
			header: "Lot Types",
			cell: (v) => {
				let val = v.getValue()
				if (Array.isArray(val)) {
					let ret = []
					val.forEach((v) => {
						if (v && v < 500) ret.push(v)
						else if (v && v >= 500) ret.push(helpers.translateLargeLotTypes(v))
					})
					return ret.join(", ")
				} else return ""
			},
		},
		{
			id: "builders_names",
			header: "Builders",
		},
		{
			id: "starts",
			header: "Quarterly Starts",
		},
		{
			id: "futures",
			header: "Futures",
		},
		{
			id: "vdls",
			header: "VDL",
		},
		{
			id: "closings",
			header: "Quarterly Closings",
		},
		{
			id: "complete_vacant",
			header: "Finished Vacant",
		},
	],
	subdivisionColumns: [
		/* {
			id: "id",
			header: "ID",
		}, */
		{
			id: "section",
			header: "Section",
		},
		{
			id: "subdivisionName",
			header: "Neighborhood",
		},
		{
			id: "price_range_text",
			header: "Price Range",
		},
		{
			id: "sqft_range_text",
			header: "SF Range",
		},
		{
			id: "quarter_start",
			header: "Active Quarter",
		},
		{
			id: "quarter_complete",
			header: "Builtout Quarter",
		},
		{
			id: "annual_starts",
			header: "Annual Starts",
		},
		{
			id: "annual_closings",
			header: "Annual Closings",
		},
		{
			id: "under_construction",
			header: "Under Construction",
		},
		{
			id: "complete_vacant",
			header: "Finished Vacant",
		},
		{
			id: "models",
			header: "Models",
		},
		{
			id: "occupied",
			header: "Occupied",
		},
		{
			id: "vdls",
			header: "VDL",
		},
		{
			id: "futures",
			header: "Futures",
		},
		{
			id: "total",
			header: "Total",
		},
	],
}
